<template>
  <div class="page-wrapper">
    <div class="banner-box" @mouseenter="stopSwiper" @mouseleave="startSwiper">
      <ul class="banner-list">
        <li
          v-for="(banner, index) in bannerData"
          :key="index"
          :style="`zIndex:${bannerData.length - index}`"
          :class="{ active: currentbanner == index }"
        >
          <img :src="banner" class="banner-img" />
        </li>
      </ul>
      <div class="banner-point">
        <span
          :class="{ active: currentbanner == num - 1 }"
          v-for="num in bannerData.length"
          :key="num"
          @click="currentbanner = num - 1"
        ></span>
      </div>
      <div class="change-btn prev" @click="changeBanner(1)"><span></span></div>
      <div class="change-btn next" @click="changeBanner(2)"><span></span></div>
    </div>

    <div class="box" v-if="jieshaoData.length">
      <div class="page-title">
        <img src="../../static/tips.png" />
        <span>院方介绍</span>
      </div>

      <div class="jieshao-box">
        <div
          class="jieshao-item"
          v-for="(item, index) in jieshaoData"
          :key="index"
        >
          <template v-if="index % 2">
            <div class="item-text" style="padding-right: 48px">
              <div class="text-tit">
                <img src="../../static/right.png" />
                {{ item.title }}
              </div>
              <div class="text-pargrapher" v-html="item.content"></div>
            </div>

            <div class="item-pic">
              <img :src="item.img" alt="" />
            </div>
          </template>
          <template v-else>
            <div class="item-pic">
              <img :src="item.img" alt="" />
            </div>
            <div class="item-text" style="padding-left: 48px">
              <div class="text-tit">
                <img src="../../static/right.png" />
                {{ item.title }}
              </div>
              <div class="text-pargrapher" v-html="item.content"></div>
            </div>
          </template>
        </div>
      </div>

      <div class="page-title">
        <img src="../../static/gongYi.png" />
        <span>公益讲座</span>
      </div>

      <div class="jiangzuo-box" v-if="jiangzuoData.length">
        <ul class="jiangzuo-list">
          <li
            v-for="(item, index) in jiangzuoData"
            :key="index"
            :style="`zIndex:${jiangzuoData.length - index}`"
            :class="{ active: currentJiangZuo == index }"
          >
            <CommonVideo
              class="video active"
              :src="item.videoUrl"
              type="video/mp4"
              :poster="item.mask"
              loop="-1"
              autoplay="autoplay"
              :controls="true"
              :control="false"
              ref="myVideo"
            ></CommonVideo>
          </li>
        </ul>
        <div class="theme-hanndel">
          <div class="theme">{{ jiangzuoData[currentJiangZuo].title }}</div>
          <div class="handle" v-if="jiangzuoData.length">
            <div class="change-btn prev" @click="changeVideoLeft()">
              <img src="../../static/left.png" />
              上一期
            </div>
            <div class="change-btn next" @click="changeVideoRight()">
              下一期<img src="../../static/left.png" />
            </div>
          </div>
        </div>
      </div>

      <div class="page-title">
        <img src="../../static/zhuanJia.png" />
        <span>专家简介</span>
      </div>

      <div class="zhuangjia-box" v-if="zhuangJiaData.length">
        <ul class="zhuanjia-list">
          <template v-for="(item, index) in zhuangJiaData">
            <li :key="index" v-if="index < 5">
              <div v-if="item.avator" class="techer-avator">
                <img :src="item.avator" />
              </div>
              <div v-else class="default-avator">
                <img src="../../static/defaultAvator.png" />
              </div>
              <div class="infs" v-html="item.infs"></div></li
          ></template>
        </ul>
        <!--  <div class="more-zhuanjia">
          <span @click="viewMore"></span>
        </div> -->
      </div>
      <div class="zhuangjia-box" v-if="zhuangJiaDataList.length">
        <ul class="zhuanjia-list">
          <template v-for="(item, index) in zhuangJiaDataList">
            <li :key="index" v-if="index < 5">
              <div v-if="item.avator" class="techer-avator">
                <img :src="item.avator" />
              </div>
              <div v-else class="default-avator">
                <img src="../../static/defaultAvator.png" />
              </div>
              <div class="infs" v-html="item.infs"></div></li
          ></template>
        </ul>
        <div class="more-zhuanjia">
          <span @click="viewMore"></span>
        </div>
      </div>

      <div class="footer-banner" v-if="fotterBanner">
        <img :src="fotterBanner" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import CommonVideo from '@/components/CommonVideo.vue';
export default {
  name: "indexPage",
  components: { CommonVideo, },
  data() {
    return {
      isShow: false,
      timmer: null,
      currentbanner: 0,
      bannerData: [
        require("@/assets/image/banner1.png"),
        require("@/assets/image/banner2.png"),
        require("@/assets/image/banner3.png"),
        require("@/assets/image/banner4.png"),
      ],
      jieshaoData: [
        {
          title: "成立背景",
          img: require("@/assets/image/img.png"),
          content:
            "<p>近年来，国家对于家庭教育越来越重视。2021年10月23日，十三届全国人大常委会第三十一次会议表决通过了家庭教育促进法，这是我国首次就家庭教育进行专门立法。其指导思想明确了父母负责实施家庭教育，国家和社会为家庭教育提供指导、支持和服务。</p><p>家长是孩子的第一任老师，相信随着法律的制定的出台，家庭、学校、社会能够更好地实现互相协同、互相融合、互相促进，从而形成强大的教育合力，助力学生健康成长。</p>",
        },
        {
          title: "京点未来教育研究院",
          img: require("@/assets/image/img2.png"),
          content:
            "<p>京点未来教育研究院，总部设于我国教育文化发展中心北京，研究院整合教育系统内部和社会各界优质资源，为家长免费提供公益讲座，致力于全面普及家庭教育知识，正确传播家庭教育理念，指导家长运用科学方法解决家庭教育问题，让中国更多的父母享受与孩子一起成长的快乐。</p>",
        },
      ],

      currentJiangZuo: 0,
      jiangzuoData: [
        {
          isShow: false,
          mask: require("@/assets/image/img1.png"),
          title: "主题1：如何与青春期的孩子沟通",
          url: "",
          videoUrl: 'https://aixuebang-1310518323.file.myqcloud.com/jingdian985/gyjz/gyjz1.mp4'
        },
        {
          isShow: false,
          mask: require("@/assets/image/img1.png"),
          title: "主题2：父母哪三种模式影响孩子一生",
          url: "",
          videoUrl: 'https://aixuebang-1310518323.file.myqcloud.com/jingdian985/gyjz/gyjz2.mp4'

        },
        {
          isShow: false,
          mask: require("@/assets/image/img1.png"),
          title: "主题3：青春期孩子的人际关系和心里健康",
          url: "",
          videoUrl: 'https://aixuebang-1310518323.file.myqcloud.com/jingdian985/gyjz/gyjz3.mp4'
        },
        {
          isShow: false,
          mask: require("@/assets/image/img1.png"),
          title: "主题4：孩子手机和金钱的自我管理",
          url: "",
          videoUrl: 'https://aixuebang-1310518323.file.myqcloud.com/jingdian985/gyjz/gyjz4.mp4'
        },
        {
          isShow: false,
          mask: require("@/assets/image/img1.png"),
          title: "主题5：如何培养独立的孩子",
          url: "",
          videoUrl: 'https://aixuebang-1310518323.file.myqcloud.com/jingdian985/gyjz/gyjz5.mp4'
        },
        {
          isShow: false,
          mask: require("@/assets/image/img1.png"),
          title: "主题6：青春期孩子到底有哪些变化？",
          url: "",
          videoUrl: 'https://aixuebang-1310518323.file.myqcloud.com/jingdian985/gyjz/gyjz6.mp4'
        },
      ],
      zhuangJiaData: [
        {
          avator: require("@/assets/image/wangdaji.png"),
          infs: "<p><span>王大绩</span>著名语文特级教师</p><p>参加过北京市十多年高考阅卷</p>",
        },
        {
          avator: require("@/assets/image/zhoupeigeng.png"),
          infs: "<p><span>周沛耕</span>北大附中数学特级教师</p>国家奥林匹克集训队教练、北京队主教练<p></p>",
        },
        {
          avator: require("@/assets/image/fancunzhi.png"),
          infs: "<p><span>范存智</span>北大附中英语特级教师</p><p>教学38年，致力于高考命题和教育研究</p>",
        },
        {
          avator: require("@/assets/image/maguijun.png"),
          infs: "<p><span>马桂君</span>北师大附中物理特级教师</p><p>全国中青年物理教师授课大赛高中组一等奖</p>",
        },
        {
          avator: require("@/assets/image/zhengkeqiang.png"),
          infs: "<p><span>郑克强</span>北京市化学特级教师</p><p>北京市化学特级教师，高考命题研究专家</p>",
        },
      ],
      zhuangJiaDataList: [
        {
          avator: require("@/assets/image/liangxia.png"),
          infs: "<p><span>梁侠</span>政治特级教师</p><p>北京市政治学科带头人《中国考试》特约编委</p>",
        },
        {
          avator: require("@/assets/image/zhangdaolin.png"),
          infs: "<p><span>张道林</span>历史特级教师</p>曾任北京市重点中学北京五中历史教研组长<p></p>",
        },
        {
          avator: require("@/assets/image/tianpeihuai.png"),
          infs: "<p><span>田佩淮</span>地理特级教师</p><p>中国著名地理特级教师，清华附中地理组组长</p>",
        },
        {
          avator: require("@/assets/image/zhaoqiusheng.png"),
          infs: "<p><span>赵京秋</span>生物特级教师</p><p>北京市西城区教育研修学院生物学科教研员</p>",
        },
        {
          avator: require("@/assets/image/shimeiling.png"),
          infs: "<p><span>史美龄</span>亲子家庭教育专家</p><p>帮助孩子成人、成事、成才</p>",
        },
      ],
      fotterBanner: require("@/assets/image/bg.png"),
    };
  },
  created() { },
  mounted() {
    this.$eventBus.$emit("changeCurrent", 0);
    this.swiperBanner();
  },
  activated() {
    this.$eventBus.$emit("changeCurrent", 0);
  },
  methods: {

    onPlay(myVideo) {
      console.log(myVideo, "myVideo.paused")

      this.isShow = !this.isShow;
      if (!this.isShow) {
        myVideo.play();
      } else {
        myVideo.pause();
      }
    },
    viewMore() {
      this.$eventBus.$emit("changeCurrent", 2);
      this.$router.push({
        path: "/team",
      });
    },
    onAllPause() {
      this.jiangzuoData.forEach((item, index) => {
        this.$refs.myVideo[index].onPause()
      })
    },
    changeVideoLeft() {
      this.onAllPause()
        if (0 <= this.currentJiangZuo) {
          this.currentJiangZuo = --this.currentJiangZuo;
        } else {
          this.currentJiangZuo = this.jiangzuoData.length - 1;
        }
    },
    changeVideoRight() {
      this.onAllPause()
       if (this.currentJiangZuo < this.jiangzuoData.length - 1) {
          this.currentJiangZuo = ++this.currentJiangZuo;
        } else {
          this.currentJiangZuo = 0;
      }
    },
    changeBanner(n) {
      if (n == 1) {
        if (0 < this.currentbanner) {
          this.currentbanner = --this.currentbanner;
        } else {
          this.currentbanner = this.bannerData.length - 1;
        }
      }
      if (n == 2) {
        if (this.currentbanner < this.bannerData.length - 1) {
          this.currentbanner = ++this.currentbanner;
        } else {
          this.currentbanner = 0;
        }
      }
    },
    startSwiper() {
      this.swiperBanner();
    },
    stopSwiper() {
      clearInterval(this.timmer);
    },
    swiperBanner() {
      this.timmer = setInterval(() => {
        if (this.currentbanner < this.bannerData.length - 1) {
          this.currentbanner = ++this.currentbanner;
        } else {
          this.currentbanner = 0;
        }
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-banner {
  margin-bottom: 125px;
  img {
    width: 100%;
  }
}
.zhuangjia-box {
  margin-bottom: 45px;
  .more-zhuanjia {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
    span {
      cursor: pointer;
      width: 83px;
      height: 17px;
      background: url("../../static/viewMore.png");
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: translateX(5px);
      }
    }
  }
  .zhuanjia-list {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 200px;
      margin: 0 20px;
      .techer-avator,
      .default-avator {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-bottom: 35px;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        img {
          width: 100%;
          height: 100%;
        }
        &:hover {
          transform: scale(1.05);
        }
      }
      .default-avator {
        img {
          transform: scale(1.3);
        }
      }
      .infs {
        text-align: center;
        font-size: 14px;
        line-height: 22px;
        color: #333;
        p {
          display: flex;
          justify-content: center;
        }
        ::v-deep span {
          font-size: 15px;
          padding-right: 10px;
          font-weight: bold;
        }
      }
    }
  }
}
.jiangzuo-box {
  margin-bottom: 45px;
  .theme-hanndel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .handle {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .change-btn {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333;
        line-height: 26px;
        margin-left: 20px;
        padding-left: 10px;
        user-select: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        opacity: 0.5;
        img {
          width: 12px;
          height: 12px;
        }
        &:hover {
          opacity: 1;
        }
      }
      .prev {
        img {
          margin-right: 10px;
        }
      }
      .next {
        img {
          margin-left: 10px;
          transform: rotate(180deg);
        }
      }
    }
    .theme {
      font-size: 16px;
      line-height: 26px;
      color: #005ca9;
    }
  }
  .jiangzuo-list {
    position: relative;
    width: 100%;
    height: 675px;
    overflow: hidden;
    margin-bottom: 30px;
    li {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
      cursor: pointer;
      .video-mask {
        width: 100%;
        height: 100%;
      }
      .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        .play-btn {
          width: 81px;
          height: 74px;
        }
      }
      &:hover {
        .mask {
          opacity: 0.8;
        }
      }
    }
    .active {
      opacity: 1;
      z-index: 500 !important;
    }
  }
}
.jieshao-box {
  margin-bottom: 10px;
  .jieshao-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
    .item-pic {
      width: 522px;
      height: 313px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-text {
      flex: 1;
      .text-tit {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        font-size: 16px;
        color: #000;
        img {
          margin-right: 10px;
        }
      }
      .text-pargrapher {
        font-size: 14px;
        line-height: 26px;
        color: #333;
        ::v-deep p {
          padding: 20px 0;
        }
      }
    }
  }
}
.banner-box {
  position: relative;
  height: calc(100vw * 0.31875);
  overflow: hidden;
  margin-bottom: 54px;
  .change-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    background: #fff;
    opacity: 0.2;
    z-index: 500;
    border-radius: 50%;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: block;
      width: 100%;
      height: 100%;
      background: url("/static/left.png") center no-repeat;
      background-size: 30px;
    }
    &:hover {
      opacity: 0.6;
    }
  }
  .prev {
    left: 20px;
  }
  .next {
    right: 20px;
    span {
      transform: rotate(180deg);
    }
  }
  .banner-list {
    position: relative;
    height: 100%;
    li {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .active {
      opacity: 1;
      z-index: 500 !important;
    }
  }
  .banner-point {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      width: 8px;
      height: 8px;
      margin: 0 5px;
      border-radius: 50%;
      background: #fff;
    }
    .active {
      background: #005ca9;
    }
  }
}
</style>