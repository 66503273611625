

import common from './api/common';

//设置个对象，就不用一个个暴露了，直接暴露对象
let api = {
    ...common,
};

//暴露出这个对象
export default api;

