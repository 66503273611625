<template>
  <div id="app">
    <div class="page-header">
      <div class="box flex-sb">
        <div class="logo">
          <img src="../static/logo.png" alt="景点未来教育" />
        </div>
        <ul class="header-menu flex-sb">
          <li
            @click="changeMenu(nav, index)"
            :class="{ active: current == index }"
            v-for="(nav, index) in menuData"
            :key="index"
          >
            {{ nav.name }}
          </li>
        </ul>
      </div>
    </div>
    <keep-alive>
      <router-view />
    </keep-alive>
    <div class="page-footer">
      <div class="box flex-sb">
        <div class="infs">
          <img
            class="footer-logo"
            src="../static/footLogo.png"
            alt="京点未来教育科技"
          />
          <div class="flex-box">
            <p>全国统一免费服务电话：400-801-8691（09:00-20:00）</p>
            <p style="margin-left: 57px">服务邮箱：2547904704@qq.com</p>
          </div>
        </div>
        <div class="code">
          <img src="../static/code.png" alt="京点未来教育科技" />
        </div>
      </div>
      <div class="copyright">
        <span 
          ><a target="_blank" style="color:white;text-decoration:none;" href="https://beian.miit.gov.cn/">京ICP备2022024380号-1</a> Copyright ©2021 北京京点未来教育科技有限公司
          版权所有</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      current: "0",
      watch: {
        $route: function (val) {
          console.log(val);
          if (val.path == "/infos") {
            this.current = 1;
          } else if (val.path == "/team") {
            this.current = 2;
          }
        },
      },
      menuData: [
        {
          url: "/",
          name: "首页",
        },
        {
          url: "/infos",
          name: "研究院简介",
        },
        {
          url: "/team",
          name: "专家团队",
        },
        {
          url: "/lecture",
          name: "公益讲座",
        },
        {
          url: "/aboutUs",
          name: "关于我们",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.$eventBus.$on("changeCurrent", (i) => {
      this.current = i;
    });
  },
  destroyed() {},
  methods: {
    changeMenu(nav, index) {
      this.current = index;
      this.$router.push({
        path: nav.url,
      });
    },
  },
};
</script>

<style lang="scss">
@import "assets/css/baseStyle.scss";
</style>
<style lang="scss" scoped>
#app {
  min-height: 100vh;
  background: #fafafa;
}
.page-footer {
  height: 298px;
  background: #2b2b2b;
  color: #fff;
  font-size: 14px;
  .infs {
    height: 238px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .footer-logo {
      width: 135px;
      height: 47px;
      margin-bottom: 24px;
    }
  }
  .code {
    img {
      width: 145px;
      height: 145px;
    }
  }
  .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-top: 1px #5a5a5a solid;
    font-size: 13px;
  }
}
.page-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  height: 110px;
  background: #fff;
  box-shadow: 0 0 5px #dcdcdc;
  .box {
    height: 100%;
    .logo {
      img {
        width: 144px;
        height: 51px;
      }
    }
    .header-menu {
      font-size: 16px;
      color: #333;
      cursor: pointer;
      user-select: none;
      li {
        margin-left: 101px;
        transition: all 0.3s ease-in-out;
        &:hover {
          opacity: 0.6;
        }
      }
      .active {
        position: relative;
        color: #005ca9;
        &::after {
          position: absolute;
          left: 0;
          right: 0;
          bottom: -13px;
          content: "";
          height: 2px;
          border-radius: 1px;
          background: #005ca9;
        }
      }
    }
  }
}
</style>
