import Vue from "vue";
import VueRouter from "vue-router";
import index from "@/views/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },

  {
    path: "/infos",
    name: "infos",
    component: () => import("@/views/infos.vue"),
    children:[
      {
        path:'/',
        name:'rescher',
        component: () => import("@/views/rescher.vue"),
      },
      {
        path:'/team',
        name:'team',
        component: () => import("@/views/team.vue"),
      }
    ]
  },
  {
    path: "/lecture",
    name: "lecture",
    component: () => import("@/views/lecture.vue"),
  },

  {
    path: "/team",
    name: "team",
    component: () => import("@/views/team.vue"),
  },

  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("@/views/aboutUs.vue"),
  },

  {
    path: "*",
    name: "errorPage",
    component: () => import("@/views/errorPage.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return { x: 0, y: to.meta.savedPosition || 0 };
    }
  },
});

export default router;
